<template>
    <div class="location-vendor-setting">
        <md-card>
            <md-card-header>
                <h3>Service Area Locations</h3>
            </md-card-header>
            <md-card-content>
                <md-input-container>
                    <label for>Country</label>
                    <md-select v-model="country">
                        <md-option :key="c" :value="c" v-for="c in countries">{{ c }}</md-option>
                    </md-select>
                </md-input-container>
                <md-input-container>
                    <label for>State</label>
                    <md-select v-model="state">
                        <md-option :key="s" :value="s" v-for="s in states">{{ s }}</md-option>
                    </md-select>
                </md-input-container>
            </md-card-content>
            <md-card-header>
                <h4>Please select all counties that you service</h4>
            </md-card-header>
            <md-card-content>
                <md-checkbox :value="selectAllCounties" @change="handleCountiesSelectAll()">Select All</md-checkbox>
                <br />
                <md-checkbox :key="c" :value="hasSelect(country, state, c)" @change="handleCountyCheckbox(c, index)" v-for="(c, index) in counties">{{ c }}</md-checkbox>
            </md-card-content>
            <md-card-actions v-if="!isNew">
                <md-button @click="save" class="md-primary">Confirm</md-button>
            </md-card-actions>
        </md-card>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: {
        isNew: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            locations: [],
            country: "",
            state: "",

            selectAllCounties: false,
            selectLocations: []
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            location: "location/location"
            // locations: "location/locations"
        }),
        countries() {
            return Array.from(new Set(this.locations.map(l => l.country))).sort(function(a, b) {
                return a > b ? 1 : -1;
            });
        },
        states() {
            return Array.from(new Set(this.locations.filter(l => l.country == this.country).map(l => l.state))).sort(function(a, b) {
                return a > b ? 1 : -1;
            });
        },
        counties() {
            let counties = Array.from(new Set(this.locations.filter(l => l.state == this.state).map(l => l.county))).sort(function(a, b) {
                return a > b ? 1 : -1;
            });
            return counties;
        },

        hasSelect() {
            return (country, state, county) => {
                return !!this.selectLocations.find(l => l.country == country && l.state == state && l.county == county);
            };
        }
    },
    methods: {
        async loadAllLocations() {
            let result = await this.$store.dispatch("crud/get", {
                api: "locations",
                params: {
                    pageSize: 0
                }
            });
            this.locations = result.data || result;
        },
        async loadVendorLocations() {
            let result = await this.$store.dispatch("crud/get", {
                api: "users/me"
            });
            this.selectLocations = this.locations.filter(l => result.locations.find(rid => rid == l._id));
        },
        setDefaultSelect() {
            let selectLocations = this.selectLocations;
            if (selectLocations.length) {
                console.info(selectLocations[0]);
                this.country = selectLocations[0].country;
                this.state = selectLocations[0].state;
                // this.selectCounties = Array.from(new Set(selectLocations.map(l => l.county)));
            } else if (this.location) {
                this.country = this.location.country;
                this.state = this.location.state;
            }
        },
        handleCountyCheckbox(county) {
            let location = this.locations.find(l => l.country == this.country && l.state == this.state && l.county == county);
            if (this.hasSelect(this.country, this.state, county)) {
                this.selectLocations = this.selectLocations.filter(l => l != location);
            } else {
                this.selectLocations.push(location);
            }
        },
        handleCountiesSelectAll() {
            this.selectAllCounties = !this.selectAllCounties;
            if (this.selectAllCounties) {
                let nonSelectCounties = this.locations.filter(l => l.country == this.country && l.state == this.state).filter(l => !this.selectLocations.find(s => s._id == l._id));
                this.selectLocations = this.selectLocations.concat(nonSelectCounties);
            } else {
                let selectCounties = this.locations.filter(l => l.country == this.country && l.state == this.state).filter(l => this.selectLocations.find(s => s._id == l._id));

                this.selectLocations = this.selectLocations.filter(s => !selectCounties.find(sc => sc._id == s._id));
            }
        },
        async save() {
            let locations = this.selectLocations;
            if (this.isNew) {
                await this.$store.dispatch("crud/put", {
                    api: `users/vendor/location`,
                    data: {
                        locations: locations.map(l => l._id)
                    }
                });
            } else {
                await this.$store.dispatch("crud/put", {
                    api: `users/${this.user._id}`,
                    data: {
                        locations: locations.map(l => l._id)
                    }
                });
                await this.$store.dispatch("user/refreshProfile");
            }

            this.$store.commit("setMessage", "Save Success");
            console.info(locations);
        }
    },
    async created() {
        // await this.$store.dispatch("location/loadLocations");
        await this.loadAllLocations();
        !this.isNew && (await this.loadVendorLocations());
        this.setDefaultSelect();
    }
};
</script>

<style lang="less">
.location-vendor-setting {
    max-width: 600px;
    margin: auto;
    .md-checkbox {
        margin-right: 22px;
    }
}
</style>
